// extracted by mini-css-extract-plugin
export const root = "PlasmicSidePanel-module--root--1-8tT";
export const root__collapse = "PlasmicSidePanel-module--root__collapse--10mRw";
export const box__jf6Ls = "PlasmicSidePanel-module--box__jf6Ls--3coAa";
export const box__collapse__jf6LsR4FrB = "PlasmicSidePanel-module--box__collapse__jf6LsR4FrB--31-02";
export const userHeader = "PlasmicSidePanel-module--userHeader--3Wurg";
export const box__hg0R1 = "PlasmicSidePanel-module--box__hg0R1--ltX0O";
export const box__collapse__hg0R1R4FrB = "PlasmicSidePanel-module--box__collapse__hg0R1R4FrB--2q94l";
export const box__eVAbN = "PlasmicSidePanel-module--box__eVAbN--1avU4";
export const box__collapse__eVAbNr4FrB = "PlasmicSidePanel-module--box__collapse__eVAbNr4FrB--1rnaL";
export const box__r7S4D = "PlasmicSidePanel-module--box__r7S4D--FUnd8";
export const box__collapse__r7S4Dr4FrB = "PlasmicSidePanel-module--box__collapse__r7S4Dr4FrB--14_gT";
export const img = "PlasmicSidePanel-module--img--cZGSw";
export const box__dyKr = "PlasmicSidePanel-module--box__dyKr--133tG";
export const box__collapse__dyKrR4FrB = "PlasmicSidePanel-module--box__collapse__dyKrR4FrB--meSQp";
export const sidePanelItem__yJof4 = "PlasmicSidePanel-module--sidePanelItem__yJof4--2asZ9";
export const svg__gUfok = "PlasmicSidePanel-module--svg__gUfok--1YDmP";
export const box__bg3T = "PlasmicSidePanel-module--box__bg3T--1R17n";
export const sidePanelItem__uJfWy = "PlasmicSidePanel-module--sidePanelItem__uJfWy--VVSkt";
export const sidePanelItem__collapse__uJfWyR4FrB = "PlasmicSidePanel-module--sidePanelItem__collapse__uJfWyR4FrB--2d0zH";
export const svg__oydia = "PlasmicSidePanel-module--svg__oydia--ktiUf";
export const box__z5Ixn = "PlasmicSidePanel-module--box__z5Ixn--2e04Z";
export const sidePanelItem__ssVAx = "PlasmicSidePanel-module--sidePanelItem__ssVAx--1Pg0z";
export const svg__qtdo6 = "PlasmicSidePanel-module--svg__qtdo6--3GpGn";
export const box___8A4B = "PlasmicSidePanel-module--box___8A4B--OWjQD";
export const sidePanelItem__ktGd2 = "PlasmicSidePanel-module--sidePanelItem__ktGd2--1TmRo";
export const svg__p9LzW = "PlasmicSidePanel-module--svg__p9LzW--2G9ss";
export const box__jUwFu = "PlasmicSidePanel-module--box__jUwFu--hoIkS";
export const sidePanelItem__sbNsT = "PlasmicSidePanel-module--sidePanelItem__sbNsT--3_iXA";
export const svg___0Bnmg = "PlasmicSidePanel-module--svg___0Bnmg--1LJP9";
export const box__s2FvD = "PlasmicSidePanel-module--box__s2FvD--3Dx1l";
export const sidePanelItem___2KgtY = "PlasmicSidePanel-module--sidePanelItem___2KgtY--3WqyN";
export const svg__yA6Gl = "PlasmicSidePanel-module--svg__yA6Gl--3Nf2W";
export const box___1C0DC = "PlasmicSidePanel-module--box___1C0DC--2PZX_";
export const sidePanelItem__aKFg4 = "PlasmicSidePanel-module--sidePanelItem__aKFg4--2suFh";
export const svg___914Jf = "PlasmicSidePanel-module--svg___914Jf--1hKnc";
export const box__bA4NT = "PlasmicSidePanel-module--box__bA4NT--3LO6l";
export const box__dMcBp = "PlasmicSidePanel-module--box__dMcBp--1rY4h";
export const box__wXvOs = "PlasmicSidePanel-module--box__wXvOs--29YhZ";
export const svg__dqNAr = "PlasmicSidePanel-module--svg__dqNAr--3ReTI";
export const box__dSknm = "PlasmicSidePanel-module--box__dSknm--ttwrJ";