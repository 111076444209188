// extracted by mini-css-extract-plugin
export const root = "PlasmicSidePanelItem-module--root--fIHY9";
export const root__collapse = "PlasmicSidePanelItem-module--root__collapse--1NQHM";
export const box__q4PgD = "PlasmicSidePanelItem-module--box__q4PgD--32wRN";
export const box__collapse__q4PgD4UZ3X = "PlasmicSidePanelItem-module--box__collapse__q4PgD4UZ3X--1GVns";
export const box__sw8Qs = "PlasmicSidePanelItem-module--box__sw8Qs--109Ry";
export const box__collapse__sw8Qs4UZ3X = "PlasmicSidePanelItem-module--box__collapse__sw8Qs4UZ3X--T9huk";
export const box__tj5Wk = "PlasmicSidePanelItem-module--box__tj5Wk--2rgij";
export const box__selected__tj5WkMsMtx = "PlasmicSidePanelItem-module--box__selected__tj5WkMsMtx--2wqlG";
export const root__selected = "PlasmicSidePanelItem-module--root__selected--261nq";
export const slotIcon = "PlasmicSidePanelItem-module--slotIcon--2EtE4";
export const slotIcon__selected = "PlasmicSidePanelItem-module--slotIcon__selected--fSd3-";
export const box__lBhXa = "PlasmicSidePanelItem-module--box__lBhXa--8YAKw";
export const slotName__selected = "PlasmicSidePanelItem-module--slotName__selected--SbEZo";