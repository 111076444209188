// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react"
import { classNames } from "@plasmicapp/react-web"

export type ContactIconProps = React.ComponentProps<"svg"> & {
  title?: string
}

export function ContactIcon(props: ContactIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 337.559 337.559"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M337.559 67.704v-28.33c0-17.506-14.242-31.748-31.748-31.748h-54.572a32.297 32.297 0 00-16.922-4.765H32.5C14.58 2.86 0 17.44 0 35.36v266.838c0 17.921 14.58 32.5 32.5 32.5h201.816a32.3 32.3 0 0016.925-4.767h54.569c17.506 0 31.748-14.242 31.748-31.748v-28.33c0-9.715-4.391-18.42-11.287-24.248 6.896-5.828 11.287-14.533 11.287-24.248v-28.331c0-9.715-4.391-18.42-11.287-24.248 6.896-5.828 11.287-14.533 11.287-24.248V116.2c0-9.715-4.391-18.42-11.287-24.248 6.897-5.829 11.288-14.534 11.288-24.248zm-85.743 234.494c0 9.649-7.851 17.5-17.5 17.5H32.5c-9.649 0-17.5-7.851-17.5-17.5V35.36c0-9.649 7.851-17.5 17.5-17.5h201.816c9.649 0 17.5 7.851 17.5 17.5v266.838zm70.743-4.014c0 9.235-7.513 16.748-16.748 16.748h-41.595a32.306 32.306 0 002.601-12.733v-49.093h38.994c9.235 0 16.748 7.513 16.748 16.748v28.33zm0-76.827c0 9.235-7.513 16.748-16.748 16.748h-38.994v-61.827h38.994c9.235 0 16.748 7.513 16.748 16.748v28.331zm0-76.827c0 9.235-7.513 16.748-16.748 16.748h-38.994V99.451h38.994c9.235 0 16.748 7.513 16.748 16.748v28.331zm0-76.826c0 9.235-7.513 16.748-16.748 16.748h-38.994V35.36c0-4.518-.929-8.822-2.602-12.735h41.596c9.235 0 16.748 7.513 16.748 16.748v28.331z"
        }
      ></path>

      <path
        d={
          "M40.413 230.024h185.991v15H40.413zm26.478-23.823h133.035a7.499 7.499 0 007.336-9.059c-3.595-16.907-13.071-32.176-26.474-43.02 8.782-10.818 13.689-24.438 13.689-38.522 0-33.674-27.396-61.07-61.07-61.07s-61.07 27.396-61.07 61.07c0 14.084 4.908 27.704 13.689 38.522-13.402 10.844-22.878 26.112-26.472 43.02a7.497 7.497 0 007.337 9.059zm34.452-44.617a7.499 7.499 0 001.066-11.903c-9.579-8.718-15.072-21.14-15.072-34.081 0-25.403 20.667-46.07 46.07-46.07s46.07 20.667 46.07 46.07c0 12.941-5.494 25.363-15.072 34.081a7.501 7.501 0 001.066 11.903c11.154 6.989 19.735 17.49 24.42 29.618H76.923c4.685-12.128 13.266-22.631 24.42-29.618zM63.83 259.688h139.156v15H63.83z"
        }
      ></path>
    </svg>
  )
}

export default ContactIcon
/* prettier-ignore-end */
