// extracted by mini-css-extract-plugin
export const root = "PlasmicContacts-module--root--3zDcV";
export const box___8DaCn = "PlasmicContacts-module--box___8DaCn--y3u09";
export const sidePanel = "PlasmicContacts-module--sidePanel--263a-";
export const sidePanel__expand = "PlasmicContacts-module--sidePanel__expand--2EExo";
export const box__sd2Fu = "PlasmicContacts-module--box__sd2Fu--2aQUQ";
export const box___0DpX = "PlasmicContacts-module--box___0DpX--1N0Nj";
export const pageTile__ufTTd = "PlasmicContacts-module--pageTile__ufTTd--25hb6";
export const svg__cnHu0 = "PlasmicContacts-module--svg__cnHu0--4Uoc4";
export const pageTile__ufiY = "PlasmicContacts-module--pageTile__ufiY--3kTRx";
export const svg___0Acnu = "PlasmicContacts-module--svg___0Acnu--aE15s";
export const pageTile__sR7P = "PlasmicContacts-module--pageTile__sR7P--38NBQ";
export const svg___3Pdi3 = "PlasmicContacts-module--svg___3Pdi3--1PkRn";
export const box__rIqE7 = "PlasmicContacts-module--box__rIqE7--1qpz-";
export const listcontacts = "PlasmicContacts-module--listcontacts--1VfDW";